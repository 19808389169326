import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'insider', loadChildren: './insider/insider.module#InsiderPageModule'},
  { path: 'terminal', 
    canActivate: [AuthGuard],
    loadChildren: './terminal/terminal.module#TerminalPageModule'
  },
  { path: 'namae', 
    canActivate: [AuthGuard],
    loadChildren: './namae/namae.module#NamaePageModule'
  },
  { path: 'cloud',
    canActivate: [AuthGuard],
    loadChildren: './cloud/cloud.module#CloudPageModule'
  },
  {
    path: 'data-structures',
    canActivate: [AuthGuard],
    loadChildren: './data-structures/data-structures.module#DataStructuresModule'
  },
  { path: 'workout-counter',
    canActivate: [AuthGuard],
    loadChildren: './workout-counter/workout-counter.module#WorkoutCounterPageModule'
  },
  { path: 'tiny-text',
    canActivate: [AuthGuard],
    loadChildren: './tiny-text/tiny-text.module#TinyTextPageModule' },
  { path: 'identify',
    canActivate: [AuthGuard],
    loadChildren: './identify/identify.module#IdentifyPageModule' },
  { path: '**', redirectTo: 'home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
