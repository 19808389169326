import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { FirebaseService } from './firebase.service';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { tap, map, take } from 'rxjs/operators';

interface User {
  uid: string;
  email: string;
  displayName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: Observable<User>;

  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    public afAuth: AngularFireAuth) { 
      //// Get auth data, then get firestore user document || null
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            let newUser = {
              uid: user.uid,
              email: user.email,
              displayName: user.displayName
            };
            return of(newUser);
          } else {
            return of(null)
          }
        })
      )
    }
    
    register(user){
      return new Promise<any>((resolve, reject) => {
        firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
        .then(
          res => resolve(res),
          err => reject(err))
      })
     }
   
     login(user){
      return new Promise<any>((resolve, reject) => {
        firebase.auth().signInWithEmailAndPassword(user.email, user.password)
        .then(
          res => resolve(res),
          err => reject(err))
      })
     }
   
     logout(){
      this.afAuth.auth.signOut()
      .then(function() {
        this.firebaseService.unsubscribeOnLogOut();
        //  route to insider
        this.router.navigate(['/insider']);
      }.bind(this)).catch((error) => {
        console.log('auth', error);
      });
     }
}
