import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { filter, startWith, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  private snapshotChangesSubscription: any;
  
  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth
  ){}

  getProfiles(){
    return this.afs.collection('people',  ref => ref.orderBy('lname')).snapshotChanges();
  }

  setSubscription(subscribtion) {
    this.snapshotChangesSubscription = subscribtion;
  }

  getProfile(id){
    return new Promise<any>((resolve, reject) => {
      this.afs.doc<any>('people/' + id).valueChanges()
      .subscribe(snapshots => {
        resolve(snapshots);
      }, err => {
        reject(err)
      })
    });
  }

  unsubscribeOnLogOut(){
    //remember to unsubscribe from the snapshotChanges
    if (this.snapshotChangesSubscription) {
      this.snapshotChangesSubscription.unsubscribe();
      
    }
  }

  updateProfile(id, value){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('people').doc(id).set({
        fname: value.fname,
        lname: value.lname,
        birthday: value.birthday,
        notes: value.notes,
        url: value.url
      })
      .then(
        res => resolve(true),
        err => reject(err)
      )
    })
  }

  deleteProfile(id, hasImg){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('people').doc(id).delete()
      .then(function() {
          if (hasImg) {
            // delete image from database
            let storageRef = firebase.storage().ref();
            let imageRef = storageRef.child('profileImage').child(id);
            imageRef.delete().then(function() {
              // File deleted successfully
              resolve();
            }).catch(function(error) {
              // Uh-oh, an error occurred!
            });
          } else {
            resolve();
          }
        },
        err => reject(err)
      )
    })
  }

  addProfile(value){
    return new Promise<any>((resolve, reject) => {
      this.afs.collection('people').add(value)
      .then(
        res => resolve(res),
        err => reject(err)
      )
    })
  }

  uploadImage(file, profileId){
    return new Promise<any>(function(resolve, reject) {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child('profileImage').child(profileId);
      imageRef.put(file).then(function(snapshot) {
        // add downloadable url to user object in db
        imageRef.getDownloadURL().then(function(url) {
          this.afs.collection('people').doc(profileId).update({url: url});
        }.bind(this)).catch(function(error) {
          // Handle any errors
          console.log('Couldnt get url', error);
        });
        resolve(snapshot);
      }.bind(this));
    }.bind(this))
  }

  uploadDrawing(image64, profileId) {
    return new Promise(function(resolve, reject) {
      let storageRef = firebase.storage().ref();
      let imageRef = storageRef.child('profileImage').child(profileId);
      imageRef.putString(image64, 'data_url')
      .then(function(snapshot) {
        snapshot.ref.getDownloadURL()
        .then(function(url) {
          this.afs.collection('people').doc(profileId).update({url: url});
          resolve(url);
        }.bind(this));
      }.bind(this), err => {
        reject(err);
      });
    }.bind(this));
  }
}